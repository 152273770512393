var documentComponent;

function openManuModal(el) {
    var manuEl = el.parentElement;

    if (manuEl.id) {
        documentComponent.openManufacturerModal(manuEl.id);
    } else {
        documentComponent.openManufacturerModal(manuEl.getAttribute('data-pid'));
    }

    
}

var documentService;
var printingService;

/* existing backend services for CPS manage most interactions using window.appName.app.fireEvent() in order
   to maintain control over the SPA architecture. We don't really want to have to override all of the link 
   handling in the backend services and definitely want to decouple back end API service paths from front 
   end paths and URLs everywhere possible. This will enable us to eventually support i18n in URLs without
   having to implement a big transition to API calls. Also, we don't really want to change the locators that
   are stored in the index just to support UI.
*/

var appName = "CPS";
(function(window, document, undefined) {
    window.app = function() {    
        var app =  {
            fireEvent: function(command, docId, locator, type, title) {

                const isMobile = window.matchMedia('(max-width: 768px)').matches;

                switch (command) {
                    case 'openDocument':

                        var fragmentIndex = locator.indexOf('#');

                        documentService.setScrollPosition(window.scrollY);

                        if (fragmentIndex !== -1) {
                            return documentService.navigateToLocator(type, docId, locator.substring(fragmentIndex));
                        }
                        return documentService.navigateToLocator(type, docId);
                    case 'hoverOnRef':
                    case 'hoverOnEndnote':
                    case 'hoverOnAbbr': {
                        // docId is event obj, locator is HTMLElement, type is refid
                        hoverOnAbbr(locator, type);
                        
                        return; 
                    }
                    case 'gotoRef': {
                        // docId is event obj, docId is refid
                        if (!isMobile) {
                            
                            // For non-mobile resolutions we want clicking a ref to open the external link

                            const refId = docId.getAttribute('href').replace('#', '');
                            const footnote = document.getElementById(refId).getElementsByTagName('a');
                           
                            const externLink = footnote[0].getAttribute('href');

                            if (!!externLink) {
                                window.open(externLink, '_blank').focus();
                            } else if (!!footnote[0].getAttribute('onclick')) {
                                footnote[0].click();
                            }
                        } else {
                            // Mobile Device. When clicking a ref open the tooltip instead of an external link.
                        }

                        return; 
                    }
  
                }
            }
        }
        return app;
    }();

    window.CPS =  {
        app: window.app
    };

})(window, window.document);

function sendInternalMonographLinkGA(id, text) {
    
    // Placeholder function for RxTx GA click events. Simply exists to prevent a javascript error.
}

function sendLinkToMonographGA(originalId, targetId, manufacturerID, manufacturerName, monographDocumentID) {
    // Placeholder function for RxTx GA click events.
}

function sendMonographManufacturerGA(name) {
    // Placeholder function for RxTx GA click events.
}

function sendGA (name) {
    // Placeholder function for RxTx GA click events.
}

function fireGAEvent(url) {
    // Placeholder function for RxTx GA click events.
}

function trackEvent(category, action, label, value) {
    // Placeholder function for RxTx GA click events.
}


function navigateToPath(path) {
    documentService.navigateToDoc(path);
}

function navigateToAnchor(anchorId) {
    if (anchorId[0] === '#') {
        anchorId = anchorId.substring(1);
    }
    document.querySelector('#' + anchorId).scrollIntoView({ block: 'center', inline: 'center' });
}

function printContent(docType, lang, docId, contentID) {
    var mainDivElement = document.createElement("div");
    var currentDocQualifier = null;
    documentPanel = document.getElementById(docId);
    //Preparing Header
    if (documentPanel !== null && documentPanel !== undefined) {
        var headerDiv = document.createElement("div");
        if(docType === "MONOGRAPH"){
            headerDiv.classList.add("MONOGRAPH_wrapper");
            let metaDataList = document.getElementsByTagName("meta");
            for (let i = 0; i < metaDataList.length; i++) {
                if (metaDataList[i].name === 'qualifier') {
                    currentDocQualifier = metaDataList[i].content;
                }
            }
        }else{
            headerDiv.classList.add("chapter-wrap");
        }
        document.querySelector(".active-document").querySelectorAll(".brand-list, .chapter-head, table.chapter-info").forEach(el => {
            headerDiv.appendChild(el.cloneNode(true));
        });
        mainDivElement.appendChild(headerDiv);
    }
    //Preparing Selected Content
    var decodedContentId = decodeURI(contentID);
    selectedContent = document.querySelector('#' + decodedContentId).cloneNode(true);
    var contentDivElement = document.createElement("div");
    contentDivElement.classList.add("panel-body-cls");
    contentDivElement.classList.add("orphan-section");
    contentDivElement.appendChild(selectedContent);
    mainDivElement.appendChild(contentDivElement);

    //Preparing References
    let referencesIDsArray = [];
    selectedContent.querySelectorAll('.ref').forEach(refLink => {
        var refIndex = refLink.firstChild.innerText.replace('[', '').replace(']', '');
        referencesIDsArray.push(refIndex + ',' + refLink.hash.replace('#', ''));
    });

    if (referencesIDsArray.length > 0) {
        var referencesPanel = document.createElement("div");
        var referenceHeader = document.getElementById('references').cloneNode(true).firstElementChild;
        referencesPanel.appendChild(referenceHeader);
        ulTag = document.createElement("ol");

        //Sorting References
        referencesIDsArray.sort(function (a, b) { return a.split(',')[0] - b.split(',')[0] });
        //Removing duplicates
        let lastReference = null;
        referencesIDsArray.forEach(reference => {
            reference = reference.split(',');
            if (reference[0] !== lastReference) {
                var elmnt = document.getElementById(reference[1]).cloneNode(true);
                elmnt.setAttribute("value", reference[0]);
                ulTag.appendChild(elmnt);
                lastReference = reference[0];
            }
        })
        referencesPanel.appendChild(ulTag);
        mainDivElement.appendChild(referencesPanel);
    }
    const modalData = {
        doc: documentService.getCurrentlyViewedDocument(),
        htmlElement: mainDivElement,
        docQualifier: currentDocQualifier
    };
    printingService.printContent(modalData, contentID);
}

function bannerClickEvent(bannerName, position, linkUrl) {
    if(bannerName !== null && bannerName !== undefined && bannerName !== ''){
        this.datalayerService.bannerClickEvent(bannerName, position, linkUrl);
    }else{
        this.datalayerService.bannerClickEvent("default", position, linkUrl);
    }
}

// Anchor tag click handler. Mostly used for innerhtml content but will check all anchors. 
document.addEventListener('click', e => {
    
    if (e.target.closest('a')) {
        const link = decodeURIComponent(e.target.closest('a').getAttribute('href'));
        const mouseover = e.target.closest('a').getAttribute('onmouseover');
        const isAnchorJump = e.target.closest('a').classList.contains('anchorJump');
        if (link !== null && link !== '') {
            if (link.indexOf('#') === 0 && (!mouseover || isAnchorJump)) { // page scrolling to ID. We don't want to scroll if it's a tooltip.
                e.preventDefault();
                const id = link.replace('#', '');
                scrollToId(id);
            }
        }
    }
});

document.addEventListener('DOMContentLoaded', function () {
    document.body.addEventListener('mouseover', function (event) {
        const target = event.target.closest('.dita-xref.inner_link');
        if (target && target.querySelector('sup.rel-links.ref-links')) {
            hoverOnAbbr(target, target.getAttribute('href'));
        }
    });
});

function scrollToId (id) {
    const element = document.getElementById(id);
    if (element){
        const headerOffset = 220;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        
        const activeDoc = document.querySelector('.active-document');
        const virtualScrollEl = activeDoc.querySelector('.virtualscroll-enabled');
        const scrollBehavior = !!virtualScrollEl ? "instant" : "smooth";

        window.scrollTo({
            top: offsetPosition,
            behavior: scrollBehavior
        });
    }
}

function navigateToDosageAdjustmentForDrug(e, drugId) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    const dosageAdjustmentDocId = 'dosage_adjustment_in_renal_impairment';
    // add the querystring before redirecting, to prevent a race condition where the listener doesn't have the querystring yet
    window.history.replaceState({}, '', '/' + dosageAdjustmentDocId + '?keyword=' + drugId);
    documentService.navigateToLocator('CHAPTER', dosageAdjustmentDocId, null, 'keyword=' + drugId);
}

function hoverOnAbbr(locator, href) {
    if (!document.getElementById('toolTipBackground')) {
        const isMobile = window.matchMedia('(max-width: 768px)').matches;        
        const refId = href.replace('#', '');
        var eventElement = locator;

        var rect = eventElement.getBoundingClientRect();

        const tooltipBackground = document.createElement('div');
        tooltipBackground.setAttribute('id', 'toolTipBackground');

    // ***** Tooltip and Tooltip-arrow positioning ******

        // Initializing Tooltip-background and Tooltip-container
        tooltipBackground.style.position = "fixed";
        const toolTipContainer = document.createElement('div');

        // Initializing arrow
        const arrow = document.createElement('div');
        arrow.setAttribute('id', 'toolTipArrow');
        arrow.style.position = "fixed";
        
        // ** Horizontal Positioning **
        if (rect.left < 180) {
            tooltipBackground.style.left = (rect.left - 10) + 'px';
        } else if ((window.innerWidth - 180) < rect.right) {
            tooltipBackground.style.left = (rect.right - 350) + 'px';
        } else {
            tooltipBackground.style.left = (rect.right - 180) + 'px';
        }
        arrow.style.left = (rect.right - 15) + 'px';

        // ** Vertical Positioning **
        if ((rect.top < 180) || ((locator.closest('th') != null) && (rect.top<380))) {  
            // For normal Tooltips, only display below when it's near the top of the screen
            // For Tooltips in Table Header, display below when it's at the bottom half of the screen (otherwise it might be blocked by tool bar)
            tooltipBackground.style.top = (rect.top + 15) + 'px';
            arrow.style.top = (rect.top + 18) + 'px';
            // ToolTipContainer2 is for container that shows below
            toolTipContainer.setAttribute('id', 'toolTipContainer2');
        }
        // Otherwise Tooltips will display above the button
        else {           
            tooltipBackground.style.top = (rect.top - 125) + 'px';
            toolTipContainer.setAttribute('id', 'toolTipContainer');
            arrow.style.top = (rect.top - 18) + 'px';
        }

        if (document.getElementById(refId)) {

            // Need to clone the content of the tooltip. 
            // Otherwise when removing after hover we will remove the tooltip element from the original document as well.
            const refElement = document.getElementById(refId);
            const rawContent = refElement.cloneNode(true);

            // Change the content container tag from li to div
            const tipContent = document.createElement('div');
            tipContent.setAttribute('id', 'toolTip');
            tipContent.innerHTML = rawContent.innerHTML;

            // in a few cases, the id of the endnote doesn't contain all of the text, so we grab the innerHtml of the parent
            const parentElement = refElement.parentNode;
            if (!!parentElement && parentElement.classList.contains('endnote')) {
                tipContent.innerHTML = parentElement.innerHTML.replace(refId, refId + '-tooltip');
            }

            const icon = document.createElement('span');

            // Check to see if we're dealing with an anchor tag or just plan text
            if (tipContent.getElementsByTagName('a')[0]) {

                icon.setAttribute('class', 'new-window-icon-blue');
                // some anchors have onclicks redirecting to other pages, we still want to keep those
                if (!tipContent.getElementsByTagName('a')[0].classList.contains('document_link')) {
                    tipContent.getElementsByTagName('a')[0].removeAttribute('onclick');
                }

                tipContent.getElementsByTagName('a')[0].appendChild(icon);
            } else {

                // Need to make the tooltip text unclickable if there is no href.
                // Note that using pointer-event: none was breaking the tooltip so I had to go with this
                href = eventElement.getAttribute('href');
                eventElement.setAttribute('href', 'javascript:void(0)');
                eventElement.style.cursor = 'default';
            }

            if (isMobile) {
                tipContent.addEventListener('click', (event) => {
                    const footnote = document.getElementById(refId).getElementsByTagName('a');
                    const externLink = footnote[0].getAttribute('href');
                    window.open(externLink, '_blank').focus();
                });
            }

            toolTipContainer.appendChild(tipContent);
            tooltipBackground.appendChild(toolTipContainer);

            toolTipContainer.addEventListener('click', (e) => {
                if (e.target && e.target.id == 'toolTip') {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    return false;
                }
            });
            
            
            if (locator.tagName === 'IMG') {
                eventElement = eventElement.parentNode;
            }            
            document.body.appendChild(tooltipBackground);
            document.body.appendChild(arrow);            

            // Destroy the tooltip after the user's mouse leave the area or, in the case of mobile, the user taps outside of the tooltip
            eventElement.addEventListener('mouseleave', (event) => {
                tooltipBackground.remove();
                toolTipContainer.remove();
                arrow.remove();
                icon.remove();
                eventElement.setAttribute('href', href);
            });
        } else {
            // Tooltip doesn't exist or can't be found. Might be a bad reference. Lots of those in the French content. 
        }
    }
}

function openFeedbackForm() {
    var feedbackFormTrigger = document.querySelector('#atlwdg-trigger');
    feedbackFormTrigger.click();
}